import React, { useState } from 'react'
import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
import { howItWorks, smallerBanner, firstBanner, secondBanner, paragraphContent } from "../utils/whatIsMotorInformation"
import Comparison from "../components/comparison/comparison";
import Button from '../components/button/button';
import Banner from "../components/banner/banner";
import Paragraph from '../components/paragraph/paragraph';


const WhatIsMotor = () => {

  return (

    <Layout SEOTitle={"What is motor legal protection? | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

      <Paragraph props={paragraphContent}  origin="motor" />

      <Comparison props={howItWorks} />
      <Button
        btnType="primary"
        label="How does it work?"
        url="/how-it-works"
        link="true"
      />

      <Banner props={smallerBanner} />
      <Banner props={firstBanner} />
      <Banner props={secondBanner} />

    </Layout>


  )

}

export default WhatIsMotor