import first from '../assets/first.png'
import second from '../assets/second.png'
import third from '../assets/third.png'
import bannerFirstLogo from '../assets/banner1.png'
import bannerSecondLogo from '../assets/banner2.png'

export const howItWorks = {
  title: "",
  comparison: [
    {
      image: first,
      info: "You save £30 per vehicle you insure per year, every year for life",
    },
    {
      image: second,
      info: "As you are not claiming from your own insurer, you don’t affect your no claims bonus.",
    },
    {
      image: third,
      info: "You can avoid paying an insurance excess.",
    },

  ]
}

export const smallerBanner = {
  small: true,
  displayBtn: true,
  title: "SIGN UP ONCE. PEACE OF MIND FOREVER.",
  subtitle: '',
  label: "SIGN UP NOW",
  link: "true",
  url: "/sign-up-to-fml/",
}

export const firstBanner = {
  small: false,
  image: bannerFirstLogo,
  displayBtn: true,
  title: "As featured in",
  subtitle: '',
  label: "Read the full article here",
  link: "true",
  url: "https://www.moneymagpie.com/manage-your-money/how-to-stop-losing-money-with-the-motor-legal-protection-con",
  padding: false,
}

export const secondBanner = {
  small: false,
  image: bannerSecondLogo,
  displayBtn: true,
  title: "As featured in",
  subtitle: '',
  label: "Read the full article here",
  link: "true",
  url: "https://www.thisismoney.co.uk/money/cars/article-3365639/Save-30-year-car-insurance-New-website-offers-free-legal-service.html",
  padding: false,
}


export const paragraphContent = {
  "motor": [
    {
      title: '',
      alignment: 'center',
      inline: true,
      description: [
        {
          boldTitle: 'What is motor legal protection?',
          text: "– Motor Legal Protection, also called legal expenses insurance, is usually sold as an add-on policy separate to your motor insurance policy. It is for assisting you in recovering any losses or expenses you may incur after an accident that was not your fault, such as lost earnings, taxi fares, hire car costs, treatment costs etc."
        },
        {
          text: "They usually cost around £30 a year per vehicle. Now there is a free alternative which does exactly this.",
          highlight: 'Welcome to Free Motor Legal'
        },
        {
          boldTitle: 'Do I need motor legal protection?',
          text: "We recommend you have some form of motor legal protection as without this, if you did have a non-fault accident your own insurance company would not assist you recovering any expenses you have incurred, such as your excess."
        },
        {
          text: "Without motor legal protection you would have to pursue this yourself, possibly even needing to issue your own court proceedings if there was a dispute about the claim with the insurers of the other party. Also, if you needed to claim for personal injury compensation without motor legal protection, a no win- no fee lawyer would help you, but their terms would usually mean you would lose 25% of your compensation to the lawyers in fees."
        },
        {
          text: "Regardless of how many cars, vans or motorcycles you have, you can only drive one at a time, so by joining Free Motor Legal households with more than one vehicle enjoy multiple savings."
        },
        {
          text: "Take a look at our quick video below to explain matters further."
        }
      ],
      youtubeUrl: "https://www.youtube.com/embed/lF4ZAwqIjnM"
    }
  ]
}